import React, { Component } from "react";
import Layout from "../../hoc/layout/layout";
import $ from "jquery";
import BannerImg from "../../assets/images/agenda/banner-img.png";
import timeIcon from "../../assets/images/agenda/time.svg";
import DrImg from "../../assets/images/agenda/dr-img.png";
import axios from "axios";
import { AgendaPopup } from "../../components/agendaPopup/agendaPopup";
import "./agenda.scss";
import imageNew from "../../assets/images/angle-down.svg";

var convert = require("xml-js");
const xml2js = require("xml2js");

const hasWindow = typeof window !== "undefined" ? true : false;
if (hasWindow) {
  window.loader = true;
}

class Agenda extends Component {
  state = {
    userTokenMain: "",
    agendaMaster: [],
    agenda: [],
    selectedAgenda: "",
    popupToggle: false,
    activeDate: "",
    activeTypeValue: "allEvent",
    activeMainTab: "myEvent",
    loader: true,
    fadeToggle: false,
    fadeToggleMain: false,
    activeTimeValue: "all",
    allMonthsMaine: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    hopinUrl: "",
    allMonths: [],
  };
  componentDidMount() {
    let getHopinUrl = localStorage.getItem("hopinUrl");
    this.setState({
      hopinUrl: getHopinUrl,
    });
    if (hasWindow) {
      $(".agenda-section .tab-link a").click(function (e) {
        e.preventDefault();
        var tabId = $(this).attr("href");
        $(".agenda-section .tab-link a").removeClass("active");
        $(this).addClass("active");
        $(".section-tab .tab-content").removeClass("active");
        $(".section-tab").find(tabId).addClass("active");
      });

      $(".agenda-section .left-tab-link a").click(function (e) {
        e.preventDefault();
        var leftTabId = $(this).attr("href");
        $(this).parents(".left-tab-link").find("a").removeClass("active");
        $(this).addClass("active");
        $(this)
          .parents(".tab-content")
          .find(".left-tab-content")
          .removeClass("active");
        $(".section-tab").find(leftTabId).addClass("active");
      });
    }

    let userToken = localStorage.getItem("userToken");
    this.setState({
      userTokenMain: userToken,
    });
    if (userToken) {
      // this.fetchData(userToken);
      let xmlss = `<?xml version="1.0" encoding="utf-8"?>\
      <soap:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/">\
       <soap:Body>\
      	 <getSororActivities xmlns="https://aka.sdsreg.com/WS/">\
      		 <token>I9^zLe59zL1sjRnRs81Ie7g5XLq1!tv0TB2LmOc2</token>\
      		 <showCode>22863000</showCode>\
      		 <memberKey>${userToken}</memberKey>\
      	 </getSororActivities>\
       </soap:Body>\
      </soap:Envelope>`;

      axios
        .post("https://aka.sdsreg.com/WS/PROD/service.asmx", xmlss, {
          headers: { "Content-Type": "text/xml" },
        })
        .then((res) => {
          let data = res.data;
          // console.log(data);
          xml2js.parseString(data, (err, result) => {
            if (err) {
              throw err;
            }

            // `result` is a JavaScript object
            // convert it to a JSON string
            const json = JSON.stringify(result, null, 4);

            // log JSON string
            let mainFormatedData = JSON.parse(json);
            console.log(mainFormatedData);

            let fullData = [];
            if (
              mainFormatedData["soap:Envelope"]["soap:Body"][0]
                .getSororActivitiesResponse[0].getSororActivitiesResult
            ) {
              let mainData =
                mainFormatedData["soap:Envelope"]["soap:Body"][0]
                  .getSororActivitiesResponse[0].getSororActivitiesResult[0][
                  "diffgr:diffgram"
                ][0].DocumentElement[0].AKA;

              fullData = [...mainData];
              let getMonths = fullData.map((getMonth) => {
                return getMonth.Activity_Date[0].split("/")[0];
              });
              let filteredMonths = getMonths.filter(
                (item, index) => getMonths.indexOf(item) === index
              );

              // console.log(filteredMonths);

              fullData.map((setData) => {
                setData.openEvents = false;
              });

              this.setState({
                allMonths: filteredMonths,
              });
            }

            // fullData.map((dd) => {
            //   let datasplit = dd.Activity_Date[0].split("/");
            //   let timesplit = dd.Activity_Start[0].split(":");
            //   let AMandPMplit = dd.Activity_Start[0].split(" ");
            //   console.log(datasplit, timesplit, AMandPMplit);
            //   if (AMandPMplit[1] == "PM") {
            //     dd.timeStamp = new Date(
            //       datasplit[2],
            //       parseInt(datasplit[0]) - 1,
            //       datasplit[1],
            //       parseInt(timesplit[0]) + 12,
            //       timesplit[1].split(" ")[0],
            //       "00"
            //     );
            //   } else {
            //     dd.timeStamp = new Date(
            //       datasplit[2],
            //       parseInt(datasplit[0]) - 1,
            //       datasplit[1],
            //       parseInt(timesplit[0]),
            //       timesplit[1].split(" ")[0],
            //       "00"
            //     );
            //   }
            // });
            // console.log(fullData);

            let datess = [10, 11, 12, 13, 14, 15];
            let getAllDataInOneArray = [];
            datess.map((datesss) => {
              let xmlsss = `<?xml version="1.0" encoding="utf-8"?>\
              <soap:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/">\
                <soap:Body>\
                  <getShowActivitiesDay xmlns="https://aka.sdsreg.com/WS/">\
                    <token>I9^zLe59zL1sjRnRs81Ie7g5XLq1!tv0TB2LmOc2</token>\
                    <showCode>22863000</showCode>\
                    <activityDate>07/${datesss}/2022</activityDate>\
                    <memberClass></memberClass>\
                  </getShowActivitiesDay>\
                </soap:Body>\
              </soap:Envelope>`;

              axios
                .post("https://aka.sdsreg.com/WS/PROD/service.asmx", xmlsss, {
                  headers: { "Content-Type": "text/xml" },
                })
                .then((res) => {
                  let data = res.data;
                  // console.log(data);
                  xml2js.parseString(data, (err, result) => {
                    if (err) {
                      throw err;
                    }

                    const json = JSON.stringify(result, null, 4);

                    let mainFormatedData = JSON.parse(json);

                    console.log(mainFormatedData);
                    let setFormate =
                      mainFormatedData["soap:Envelope"]["soap:Body"][0]
                        .getShowActivitiesDayResponse[0]
                        .getShowActivitiesDayResult;

                    // console.log(setFormate)

                    if (setFormate) {
                      let mainData =
                        setFormate[0]["diffgr:diffgram"][0].DocumentElement[0]
                          .AKA;

                      mainData.map((setData) => {
                        setData.openEvents = true;
                      });

                      getAllDataInOneArray = [
                        ...getAllDataInOneArray,
                        ...mainData,
                      ];

                      let finalAgenda = [...getAllDataInOneArray, ...fullData];

                      finalAgenda.map((dd) => {
                        let datasplit = dd.Activity_Date[0].split("/");
                        let timesplit = dd.Activity_Start[0].split(":");
                        let AMandPMplit = dd.Activity_Start[0].split(" ");
                        // console.log(datasplit, timesplit, AMandPMplit);
                        if (AMandPMplit[1] == "PM") {
                          if (timesplit[0] == 12) {
                            dd.timeStamp = new Date(
                              datasplit[2],
                              parseInt(datasplit[0]) - 1,
                              datasplit[1],
                              timesplit[0],
                              timesplit[1].split(" ")[0],
                              "00"
                            );
                          } else {
                            dd.timeStamp = new Date(
                              datasplit[2],
                              parseInt(datasplit[0]) - 1,
                              datasplit[1],
                              parseInt(timesplit[0]) + 12,
                              timesplit[1].split(" ")[0],
                              "00"
                            );
                          }
                        } else {
                          dd.timeStamp = new Date(
                            datasplit[2],
                            parseInt(datasplit[0]) - 1,
                            datasplit[1],
                            parseInt(timesplit[0]),
                            timesplit[1].split(" ")[0],
                            "00"
                          );
                        }
                        // console.log(dd.timeStamp);
                      });

                      // console.log(finalAgenda);

                      // const sortedActivities = finalAgenda.sort(
                      //   (a, b) => b.timeStamp - a.timeStamp
                      // );
                      finalAgenda.sort(
                        (a, b) => new Date(a.timeStamp) - new Date(b.timeStamp)
                      );
                      // const sortedActivitiess = finalAgenda
                      //   .slice()
                      //   .sort((a, b) => b.date - a.date);

                      console.log(finalAgenda);

                      // MERGING VIRTUAL AND IN-PERSON EVENTS
                      for (let i = 0; i < finalAgenda.length - 1; i++) {
                        if (finalAgenda[i + 1].Activity_Name[0] == finalAgenda[i].Activity_Name[0]
                          && finalAgenda[i + 1].Activity_Date[0] == finalAgenda[i].Activity_Date[0]
                          && finalAgenda[i + 1].Activity_Start[0] == finalAgenda[i].Activity_Start[0]
                        ) {
                          console.log(finalAgenda[i + 1].Activity_Name[0] + "  vs " + finalAgenda[i].Activity_Name[0]);
                          console.log(finalAgenda[i + 1].Activity_Date[0] + "  vs " + finalAgenda[i].Activity_Date[0]);
                          console.log(finalAgenda[i + 1].Activity_Start[0] + "  vs " + finalAgenda[i].Activity_Start[0]);
                          finalAgenda[i + 1].Activity_Type[0] = "VIRTUAL";
                          finalAgenda.splice(i, 1);
                        }
                      }

                      this.setState({
                        // agenda: [...getAllDataInOneArray, ...fullData],
                        // agendaMaster: [...getAllDataInOneArray, ...fullData],
                        agenda: finalAgenda,
                        agendaMaster: finalAgenda,
                        loader: false,
                      });

                      this.dateFilterHandler(12);
                    } else {
                      this.setState({
                        loader: false,
                      });
                      this.dateFilterHandler(12);
                    }
                  });
                });
            });
          });
        }, (error) => {
          console.log("------ERROR FROM AGENDA JS-----------");
          console.log(error);
        });
    }
  }

  // fetchData = async (userToken) => {
  //   let RegEvent = `<?xml version="1.0" encoding="utf-8"?>\
  // 		<soap:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/">\
  // 		 <soap:Body>\
  // 			 <getSororActivities xmlns="https://www.sdsreg.com/AKA/WS/">\
  // 				 <token>I9^zLe59zL1sjRnRs81Ie7g5XLq1!tv0TB2LmOc2</token>\
  // 				 <showCode>21991000</showCode>\
  // 				 <memberKey>${userToken}</memberKey>\
  // 			 </getSororActivities>\
  // 		 </soap:Body>\
  // 		</soap:Envelope>`;

  //   let getRegEvent = await axios.post(
  //     "https://aka.sdsreg.com/WS/PROD/service.asmx",
  //     RegEvent,
  //     {
  //       headers: { "Content-Type": "text/xml" },
  //     }
  //   );

  //   // let eventDates = [12, 13, 14, 15, 16];
  //   // let getAllDataInOneArray = [getRegEvent];

  //   let OpenEvent12 = `<?xml version="1.0" encoding="utf-8"?>\
  // 		<soap:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/">\
  // 		 <soap:Body>\
  // 			 <getShowActivitiesDay xmlns="https://www.sdsreg.com/AKA/WS/">\
  // 				 <token>I9^zLe59zL1sjRnRs81Ie7g5XLq1!tv0TB2LmOc2</token>\
  // 				 <showCode>21991000</showCode>\
  // 				 <activityDate>07/12/2021</activityDate>\
  // 				 <memberClass></memberClass>\
  // 			 </getShowActivitiesDay>\
  // 		 </soap:Body>\
  // 		</soap:Envelope>`;

  //   let getOpenEvent12 = await axios.post(
  //     "https://aka.sdsreg.com/WS/PROD/service.asmx",
  //     OpenEvent12,
  //     {
  //       headers: { "Content-Type": "text/xml" },
  //     }
  //   );

  //   let OpenEvent13 = `<?xml version="1.0" encoding="utf-8"?>\
  // 		<soap:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/">\
  // 		 <soap:Body>\
  // 			 <getShowActivitiesDay xmlns="https://www.sdsreg.com/AKA/WS/">\
  // 				 <token>I9^zLe59zL1sjRnRs81Ie7g5XLq1!tv0TB2LmOc2</token>\
  // 				 <showCode>21991000</showCode>\
  // 				 <activityDate>07/13/2021</activityDate>\
  // 				 <memberClass></memberClass>\
  // 			 </getShowActivitiesDay>\
  // 		 </soap:Body>\
  // 		</soap:Envelope>`;

  //   let getOpenEvent13 = await axios.post(
  //     "https://aka.sdsreg.com/WS/PROD/service.asmx",
  //     OpenEvent13,
  //     {
  //       headers: { "Content-Type": "text/xml" },
  //     }
  //   );

  //   let OpenEvent14 = `<?xml version="1.0" encoding="utf-8"?>\
  // 		<soap:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/">\
  // 		 <soap:Body>\
  // 			 <getShowActivitiesDay xmlns="https://www.sdsreg.com/AKA/WS/">\
  // 				 <token>I9^zLe59zL1sjRnRs81Ie7g5XLq1!tv0TB2LmOc2</token>\
  // 				 <showCode>21991000</showCode>\
  // 				 <activityDate>07/14/2021</activityDate>\
  // 				 <memberClass></memberClass>\
  // 			 </getShowActivitiesDay>\
  // 		 </soap:Body>\
  // 		</soap:Envelope>`;

  //   let getOpenEvent14 = await axios.post(
  //     "https://aka.sdsreg.com/WS/PROD/service.asmx",
  //     OpenEvent14,
  //     {
  //       headers: { "Content-Type": "text/xml" },
  //     }
  //   );

  //   let OpenEvent15 = `<?xml version="1.0" encoding="utf-8"?>\
  // 		<soap:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/">\
  // 		 <soap:Body>\
  // 			 <getShowActivitiesDay xmlns="https://www.sdsreg.com/AKA/WS/">\
  // 				 <token>I9^zLe59zL1sjRnRs81Ie7g5XLq1!tv0TB2LmOc2</token>\
  // 				 <showCode>21991000</showCode>\
  // 				 <activityDate>07/15/2021</activityDate>\
  // 				 <memberClass></memberClass>\
  // 			 </getShowActivitiesDay>\
  // 		 </soap:Body>\
  // 		</soap:Envelope>`;

  //   let getOpenEvent15 = await axios.post(
  //     "https://aka.sdsreg.com/WS/PROD/service.asmx",
  //     OpenEvent15,
  //     {
  //       headers: { "Content-Type": "text/xml" },
  //     }
  //   );

  //   let OpenEvent16 = `<?xml version="1.0" encoding="utf-8"?>\
  // 		<soap:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/">\
  // 		 <soap:Body>\
  // 			 <getShowActivitiesDay xmlns="https://www.sdsreg.com/AKA/WS/">\
  // 				 <token>I9^zLe59zL1sjRnRs81Ie7g5XLq1!tv0TB2LmOc2</token>\
  // 				 <showCode>21991000</showCode>\
  // 				 <activityDate>07/16/2021</activityDate>\
  // 				 <memberClass></memberClass>\
  // 			 </getShowActivitiesDay>\
  // 		 </soap:Body>\
  // 		</soap:Envelope>`;

  //   let getOpenEvent16 = await axios.post(
  //     "https://aka.sdsreg.com/WS/PROD/service.asmx",
  //     OpenEvent16,
  //     {
  //       headers: { "Content-Type": "text/xml" },
  //     }
  //   );

  //   console.log(
  //     getOpenEvent12,
  //     getOpenEvent13,
  //     getOpenEvent14,
  //     getOpenEvent15,
  //     getOpenEvent16,
  //     getRegEvent
  //   );

  //   let manageData = [
  //     getOpenEvent12,
  //     getOpenEvent13,
  //     getOpenEvent14,
  //     getOpenEvent15,
  //     getOpenEvent16,
  //     getRegEvent,
  //   ];

  //   let allDataInOne = [];
  //   manageData.map((xmlData) => {
  //     xml2js.parseString(xmlData.data, (err, result) => {
  //       if (err) {
  //         throw err;
  //       }

  //       const json = JSON.stringify(result, null, 4);

  //       let mainFormatedData = JSON.parse(json);
  //       console.log(mainFormatedData);

  //       let setFormate =
  //         mainFormatedData["soap:Envelope"]["soap:Body"][0]
  //           .getShowActivitiesDayResponse;

  //       let activiteSetFormate =
  //         mainFormatedData["soap:Envelope"]["soap:Body"][0]
  //           .getSororActivitiesResponse;
  //       if (setFormate) {
  //         let mainData =
  //           setFormate[0].getShowActivitiesDayResult[0]["diffgr:diffgram"][0]
  //             .DocumentElement[0].AKA;

  //         mainData.map((setData) => {
  //           setData.openEvents = true;
  //         });
  //         allDataInOne = [...allDataInOne, ...mainData];
  //         // console.log(mainData);
  //       }
  //       if (activiteSetFormate) {
  //         let avtivityData =
  //           activiteSetFormate[0].getSororActivitiesResult[0][
  //             "diffgr:diffgram"
  //           ][0].DocumentElement[0].AKA;

  //         avtivityData.map((setData) => {
  //           setData.openEvents = false;
  //         });
  //         allDataInOne = [...allDataInOne, ...avtivityData];
  //       }
  //     });
  //   });

  //   allDataInOne.map((dd) => {
  //     let datasplit = dd.Activity_Date[0].split("/");
  //     let timesplit = dd.Activity_Start[0].split(":");
  //     let AMandPMplit = dd.Activity_Start[0].split(" ");
  //     // console.log(datasplit, timesplit, AMandPMplit);
  //     if (AMandPMplit[1] == "PM") {
  //       dd.timeStamp = new Date(
  //         datasplit[2],
  //         parseInt(datasplit[0]) - 1,
  //         datasplit[1],
  //         parseInt(timesplit[0]) + 12,
  //         timesplit[1].split(" ")[0],
  //         "00"
  //       );
  //     } else {
  //       dd.timeStamp = new Date(
  //         datasplit[2],
  //         parseInt(datasplit[0]) - 1,
  //         datasplit[1],
  //         parseInt(timesplit[0]),
  //         timesplit[1].split(" ")[0],
  //         "00"
  //       );
  //     }
  //     // console.log(dd.timeStamp);
  //   });

  //   allDataInOne.sort((a, b) => new Date(a.timeStamp) - new Date(b.timeStamp));

  // 	console.log(allDataInOne);

  // };

  openPopupHandler = (data) => {
    this.setState({
      popupToggle: true,
      selectedAgenda: data,
    });
  };

  activityTypeHandler = (e) => {
    this.setState({
      activeTypeValue: e.target.value,
      fadeToggle: true,
      activeTimeValue: "all",
    });
    setTimeout(() => {
      this.setState({
        fadeToggle: false,
      });

      let allData = [...this.state.agendaMaster];
      let getDataByDate = allData.filter((fil) => {
        if (fil.Activity_Date[0].split("/")[1] == this.state.activeDate) {
          return fil;
        }
      });
      if (e.target.value == "allEvent") {
        this.setState({
          agenda: getDataByDate,
        });
      }
      if (e.target.value == "registeredEvent") {
        let getData = getDataByDate.filter((fil) => {
          // console.log(fil.Activity_Function[0]);
          if (fil.openEvents == false) {
            return fil;
          }
        });

        this.setState({
          agenda: getData,
        });
      }
      if (e.target.value == "openEvent") {
        let getData = getDataByDate.filter((fil) => {
          // console.log(fil.Activity_Function[0]);
          if (fil.openEvents) {
            if (fil.openEvents == true) {
              return fil;
            }
          }
        });
        this.setState({
          agenda: getData,
        });
      }
      if (e.target.value == "virtualEvent") {
        let getData = getDataByDate.filter((fil) => {
          if (fil.Activity_Type[0]) {
            if (fil.Activity_Type[0] == 'VIRTUAL') {
              return fil;
            }
          }
        });
        this.setState({
          agenda: getData,
        });
      }
    }, 500);
  };

  dateFilterHandler = (data) => {
    console.log(data);
    if (data != this.state.activeDate) {
      this.setState({
        fadeToggle: true,
        activeDate: data,
        activeTimeValue: "all",
      });
      setTimeout(() => {
        this.setState({
          fadeToggle: false,
        });

        let allData = [...this.state.agendaMaster];
        let getData = allData.filter((fil) => {
          // console.log(fil.Activity_Date[0].split("/")[1]);
          if (fil.Activity_Date[0].split("/")[1] == data) {
            return fil;
          }
        });
        if (this.state.activeMainTab == "myEvent") {
          this.setState({
            agenda: getData,
            activeTypeValue: "allEvent",
          });
          // let hhh =
          // this.activityTypeHandler({ e: { target: { value: "allEvent" } } });
        } else {
          let filterOnlyRegEvent = getData.filter((data) => {
            if (data.openEvents) {
              return data;
            }
          });
          this.setState({
            agenda: filterOnlyRegEvent,
          });
        }
      }, 500);
    }

    // console.log(getData);
  };

  mainTabHandler = (data) => {
    this.setState({
      fadeToggleMain: true,
      activeMainTab: data,
      activeTypeValue: "allEvent",
      activeTimeValue: "all",
    });

    setTimeout(() => {
      this.setState({
        fadeToggleMain: false,
      });

      let allData = [...this.state.agendaMaster];
      if (data == "leadership") {
        let getData = allData.filter((fil) => {
          // if (new Date().getDate() > 12) {
          // 	if (fil.Activity_Date[0].split("/")[1] == 13) {
          // 		return fil;
          // 	}
          // }
          // if (new Date().getDate() > 13) {
          // 	if (fil.Activity_Date[0].split("/")[1] == 13) {
          // 		return fil;
          // 	}
          // }
          if (fil.Activity_Date[0].split("/")[1] == 12) {
            return fil;
          }
        });
        let filterOnlyRegEvent = getData.filter((data) => {
          if (data.openEvents) {
            return data;
          }
        });
        // let filterDataByDate = filterOnlyRegEvent.filter(data => {
        // 	if (data.Activity_Date[0].split("/")[1] == 12) {
        // 		return fil;
        // 	}
        // })
        this.setState({
          agenda: filterOnlyRegEvent,
          activeDate: 12,
        });
      }
      if (data == "boule") {
        let getData = allData.filter((fil) => {
          if (fil.Activity_Date[0].split("/")[1] == 10) {
            return fil;
          }
        });
        let filterOnlyRegEvent = getData.filter((data) => {
          if (data.openEvents) {
            return data;
          }
        });
        this.setState({
          agenda: filterOnlyRegEvent,
          activeDate: 10,
        });
        // this.dateFilterHandler(14);
      }
      if (data == "myEvent") {
        let getData = allData.filter((fil) => {
          if (fil.Activity_Date[0].split("/")[1] == 12) {
            return fil;
          }
        });
        this.setState({
          agenda: getData,
          activeDate: 12,
        });
      }
    }, 500);
  };

  timeHandler = (e) => {
    this.setState({
      fadeToggle: true,
      activeTimeValue: e.target.value,
      activeTypeValue: "allEvent"
    });

    setTimeout(() => {
      this.setState({
        fadeToggle: false,
      });

      let allData = [...this.state.agendaMaster];
      let getDataByDate = allData.filter((fil) => {
        if (fil.Activity_Date[0].split("/")[1] == this.state.activeDate) {
          return fil;
        }
      });

      if (e.target.value == "all") {
        this.setState({
          agenda: getDataByDate,
        });
      }

      if (e.target.value == "morning") {
        let getData = getDataByDate.filter((fil) => {
          let getvalues = fil.timeStamp.getHours();
          console.log(getvalues);
          if (getvalues > 6 && getvalues < 12) {
            return fil;
          }
        });
        this.setState({
          agenda: getData,
        });
      }

      if (e.target.value == "afternoon") {
        let getData = getDataByDate.filter((fil) => {
          let getvalues = fil.timeStamp.getHours();
          console.log(getvalues);
          if (getvalues > 11 && getvalues < 18) {
            return fil;
          }
        });
        this.setState({
          agenda: getData,
        });
      }
      if (e.target.value == "evening") {
        let getData = getDataByDate.filter((fil) => {
          let getvalues = fil.timeStamp.getHours();
          console.log(getvalues);
          if (getvalues > 16) {
            return fil;
          }
        });
        let getData2 = getDataByDate.filter((fil) => {
          let getvalues = fil.timeStamp.getHours();
          console.log(getvalues);
          if (getvalues < 6) {
            return fil;
          }
        });
        let finalData = [...getData, ...getData2];
        console.log(getData);
        this.setState({
          agenda: finalData,
        });
      }
    }, 500);
  };

  dateSelectHandler = (e) => {
    if (e.target.value != this.state.activeDate) {
      this.setState({
        fadeToggle: true,
        activeDate: e.target.value,
        activeTimeValue: "all",
        activeTypeValue: "allEvent",
      });
      setTimeout(() => {
        this.setState({
          fadeToggle: false,
        });

        let allData = [...this.state.agendaMaster];
        let getData = allData.filter((fil) => {
          // console.log(fil.Activity_Date[0].split("/")[1]);
          if (fil.Activity_Date[0].split("/")[1] == parseInt(e.target.value)) {
            return fil;
          }
        });
        if (this.state.activeMainTab == "myEvent") {
          this.setState({
            agenda: getData,

            activeTypeValue: "allEvent",
          });
          // let hhh =
          // this.activityTypeHandler({ e: { target: { value: "allEvent" } } });
        } else {
          let filterOnlyRegEvent = getData.filter((data) => {
            if (data.openEvents) {
              return data;
            }
          });
          this.setState({
            agenda: filterOnlyRegEvent,
          });
        }
      }, 500);
    }

    // // console.log(e.target.value);
    // let allData = [...this.state.agendaMaster];

    // let getData = allData.filter((fil) => {
    //   if (fil.Activity_Date[0].split("/")[1] == parseInt(e.target.value)) {
    //     return fil;
    //   }
    // });
    // if (this.state.activeMainTab == "myEvent") {
    //   this.setState({
    //     agenda: getData,
    //     activeDate: e.target.value,
    //   });
    // } else {
    //   let filterOnlyRegEvent = getData.filter((data) => {
    //     if (data.openEvents) {
    //       return data;
    //     }
    //   });
    //   this.setState({
    //     agenda: filterOnlyRegEvent,
    //     activeDate: e.target.value,
    //   });
    // }
    // // console.log(getData);
    // // this.setState({
    // //   agenda: getData,
    // //   activeDate: e.target.value,
    // // });
  };


  render() {
    return (
      <>
        <div
          id="overlay"
          className={this.state.loader ? "loader" : "loader active"}
        >
          <div className="inner-box">
            {/* <div className="loader-circle"></div>
            <div className="loader-line-mask">
              <div className="loader-line"></div>
            </div> */}
          </div>
        </div>
        {/* <div className="loader-box"></div> */}
        <AgendaPopup
          popupToggle={this.state.popupToggle}
          closeHandler={() => this.setState({ popupToggle: false })}
          selectedAgenda={this.state.selectedAgenda}
        />
        <div className="innerpage-sec agenda-section">
          <div className="overlay">
            <img src={BannerImg} alt="" />
          </div>

          <div className="section-header">
            <div className="inner">
              <h1>AGENDA</h1>
              <p>
                Navigate the schedule below to find more information about the 70th Boule plenary sessions, forums, and special events.
              </p>
            </div>
          </div>

          <div className="section-tab">
            <div className="tab-link">
              <ul>
                <li>
                  <button
                    type="button"
                    className={
                      this.state.activeMainTab == "myEvent" ? "active" : ""
                    }
                    onClick={() => this.mainTabHandler("myEvent")}
                  >
                    My Events
                  </button>
                </li>
                {/* <li>
                  <button
                    type="button"
                    className={
                      this.state.activeMainTab == "leadership" ? "active" : ""
                    }
                    onClick={() => this.mainTabHandler("leadership")}
                  >
                    Leadership
                  </button>
                </li> */}
                <li>
                  <button
                    type="button"
                    className={
                      this.state.activeMainTab == "boule" ? "active" : ""
                    }
                    onClick={() => this.mainTabHandler("boule")}
                  >
                    All boule events
                  </button>
                </li>
              </ul>
            </div>
            <div className="tab-content active" id="myEvent">
              <div className="tab-sec-header">
                <div className="mobile-left-tab">
                  <label htmlFor="select-box">Choose Date</label>
                  <select
                    id="select-box"
                    onChange={this.dateSelectHandler}
                    className="select-box"
                  >
                    {this.state.allMonths.map((mon, key) => (
                      <>
                        <>
                          <option value="10">
                            Day 1{" "}
                            {this.state.allMonthsMaine[parseInt(mon) - 1]} 10{" "}
                          </option>
                          <option value="11">
                            Day 2{" "}
                            {this.state.allMonthsMaine[parseInt(mon) - 1]} 11{" "}
                          </option>
                          <option value="12">
                            Day 3{" "}
                            {this.state.allMonthsMaine[parseInt(mon) - 1]} 12{" "}
                          </option>
                          <option value="13">
                            Day 4{" "}
                            {this.state.allMonthsMaine[parseInt(mon) - 1]} 13{" "}
                          </option>
                          <option value="14">
                            Day 5{" "}
                            {this.state.allMonthsMaine[parseInt(mon) - 1]} 14{" "}
                          </option>
                          <option value="15">
                            Day 6{" "}
                            {this.state.allMonthsMaine[parseInt(mon) - 1]} 15{" "}
                          </option>
                        </>
                        {/* {this.state.activeMainTab !== "leadership" ? (
                          <>
                            <option value="14">
                              Day 3{" "}
                              {this.state.allMonthsMaine[parseInt(mon) - 1]} 14{" "}
                            </option>
                            <option value="15">
                              Day 4{" "}
                              {this.state.allMonthsMaine[parseInt(mon) - 1]} 15{" "}
                            </option>
                            <option value="16">
                              Day 5{" "}
                              {this.state.allMonthsMaine[parseInt(mon) - 1]} 16{" "}
                            </option>
                          </>
                        ) : (
                          <option></option>
                        )} */}
                      </>
                    ))}
                  </select>
                </div>
                <div className="filter-sec">
                  <p className="item-listed">
                    All times listed below are in the Central Daylight Time.
                    {/* In the Center Time Zone. */}
                  </p>
                  <div className="inner">
                    <p className="filter-label">Filter:</p>
                    {/* <div className="form-group">
                      <div className="angle-down">
                        <img src={imageNew} alt="" />
                      </div>
                      <label htmlFor="bytime">By Time</label>
                      <select
                        name=""
                        value={this.state.activeTimeValue}
                        onChange={this.timeHandler}
                        id="bytime"
                      >
                        <option value="all">All</option>
                        <option value="morning">Morning (7am to 12pm) </option>
                        <option value="afternoon">
                          Afternoon (12pm to 5pm){" "}
                        </option>
                        <option value="evening">Evening (5pm or later) </option>
                      </select>
                    </div> */}
                    {/* {this.state.activeMainTab == "myEvent" ? ( */}
                    <div className="form-group">
                      <div className="angle-down">
                        <img src={imageNew} alt="" />
                      </div>
                      <label htmlFor="activity">Event type</label>
                      <select
                        value={this.state.activeTypeValue}
                        name=""
                        onChange={this.activityTypeHandler}
                        id="activity"
                      >
                        {/* <option value="MEAL">MEAL</option>
                        <option value="RECEPTION">RECEPTION</option>
                        <option value="WORKSHOP">WORKSHOP</option>
                        <option value="FORUM">FORUM</option>
                        <option value="SESSIONS">SESSIONS</option>
                        <option value="SERVICE">SERVICE</option>
                        <option value="PARTY">PARTY</option>
                        <option value="TOUR">TOUR</option> */}
                        <option value="allEvent">ALL EVENTS</option>
                        <option value="virtualEvent">VIRTUAL ACCESS</option>
                        <option value="registeredEvent">
                          REGISTERED EVENTS
                        </option>
                        <option value="openEvent">OPEN EVENTS</option>
                      </select>
                    </div>
                    {/* ) : null} */}

                    {/* <p className="all-result">Showing: All Results</p> */}
                  </div>
                </div>
              </div>

              <div
                className={
                  this.state.fadeToggleMain
                    ? "inner-tab-content active"
                    : "inner-tab-content"
                }
              >
                <div
                  style={{ opacity: this.state.fadeToggleMain ? 0 : 1 }}
                  className="left-tab-link"
                >
                  <ul>
                    {this.state.activeMainTab !== "leadership" ? (
                      <>
                        <li>
                          <button
                            type="button"
                            onClick={() => this.dateFilterHandler(10)}
                            className={
                              new Date().getDate() > 10
                                ? this.state.activeDate == 10
                                  ? "btn active"
                                  : "btn"
                                : this.state.activeDate == 10
                                  ? "btn active"
                                  : "btn"
                            }
                          >
                            <h4>Day 1</h4> <span>July 10</span>
                          </button>
                        </li>
                        <li>
                          <button
                            type="button"
                            onClick={() => this.dateFilterHandler(11)}
                            className={
                              new Date().getDate() > 11
                                ? this.state.activeDate == 11
                                  ? "btn active"
                                  : "btn"
                                : this.state.activeDate == 11
                                  ? "btn active"
                                  : "btn"
                            }
                          >
                            <h4>Day 2</h4> <span>July 11</span>
                          </button>
                        </li>
                        <li>
                          <button
                            type="button"
                            onClick={() => this.dateFilterHandler(12)}
                            className={
                              new Date().getDate() > 12
                                ? this.state.activeDate == 12
                                  ? "btn active"
                                  : "btn"
                                : this.state.activeDate == 12
                                  ? "btn active"
                                  : "btn"
                            }
                          >
                            <h4>Day 3</h4> <span>July 12</span>
                          </button>
                        </li>
                        <li>
                          <button
                            type="button"
                            onClick={() => this.dateFilterHandler(13)}
                            className={
                              new Date().getDate() > 13
                                ? this.state.activeDate == 13
                                  ? "btn active"
                                  : "btn"
                                : this.state.activeDate == 13
                                  ? "btn active"
                                  : "btn"
                            }
                          >
                            <h4>Day 4</h4> <span>July 13</span>
                          </button>
                        </li>
                        <li>
                          <button
                            type="button"
                            onClick={() => this.dateFilterHandler(14)}
                            className={
                              new Date().getDate() > 14
                                ? this.state.activeDate == 14
                                  ? "btn active"
                                  : "btn"
                                : this.state.activeDate == 14
                                  ? "btn active"
                                  : "btn"
                            }
                          >
                            <h4>Day 5</h4> <span>July 14</span>
                          </button>
                        </li>
                        <li>
                          <button
                            type="button"
                            onClick={() => this.dateFilterHandler(15)}
                            className={
                              new Date().getDate() > 15
                                ? this.state.activeDate == 15
                                  ? "btn active"
                                  : "btn"
                                : this.state.activeDate == 15
                                  ? "btn active"
                                  : "btn"
                            }
                          >
                            <h4>Day 6</h4> <span>July 15</span>
                          </button>
                        </li>
                      </>
                    ) : null}

                    {/* {this.state.activeMainTab !== "leadership" ? (
                      <>
                        <li>
                          <button
                            type="button"
                            onClick={() => this.dateFilterHandler(14)}
                            className={
                              new Date().getDate() > 14
                                ? this.state.activeDate == 14
                                  ? "btn active"
                                  : "btn"
                                : this.state.activeDate == 14
                                  ? "btn active"
                                  : "btn"
                            }
                          >
                            <h4>Day 3</h4> <span>July 14</span>
                          </button>
                        </li>
                        <li>
                          <button
                            type="button"
                            onClick={() => this.dateFilterHandler(15)}
                            className={
                              new Date().getDate() > 15
                                ? this.state.activeDate == 15
                                  ? "btn active"
                                  : "btn"
                                : this.state.activeDate == 15
                                  ? "btn active"
                                  : "btn"
                            }
                          >
                            <h4>Day 4</h4> <span>July 15</span>
                          </button>
                        </li>
                        <li>
                          <button
                            type="button"
                            onClick={() => this.dateFilterHandler(16)}
                            className={
                              new Date().getDate() > 16
                                ? this.state.activeDate == 16
                                  ? "btn active"
                                  : "btn"
                                : this.state.activeDate == 16
                                  ? "btn active"
                                  : "btn"
                            }
                          >
                            <h4>Day 5</h4> <span>July 16</span>
                          </button>
                        </li>
                      </>
                    ) : null} */}
                  </ul>
                </div>

                <div
                  className={
                    this.state.fadeToggle ? "right-sec active" : "right-sec"
                  }
                >
                  <div className="left-tab-content active" id="eventDay1">
                    {/* <div className="time-heading border-dotted">
                      <h2>
                        <span>8 AM</span>
                        <span className="inner-dotted"></span>
                      </h2>
                    </div> */}
                    {/* <div className="lunch-break border-dotted">
                      <h3>
                        <span>
                          14 - 16 PM <i>Lunch break</i>
                        </span>
                      </h3>
                    </div> */}

                    {this.state.agenda.length > 0 ? (
                      this.state.agenda.map((agd, key) => (
                        <div key={key} className="post-box">
                          <div className="post-body">
                            <div className="left-content">
                              <h4>{agd.Activity_Name[0]}</h4>
                              {/* <p>{agd.Activity_Description[0]}</p> */}
                            </div>

                            <div className="post-register">
                              {agd.openEvents ? (
                                <p className="register-txt open-txt">
                                  {agd.Activity_Location[0]}
                                </p>
                              ) : (
                                <p className="register-txt">Registered Event</p>
                              )}
                              <p className="time-txt">
                                {agd.Activity_Start[0]} to {agd.Activity_End[0]}{" "}
                                <img src={timeIcon} alt="" />
                              </p>
                            </div>
                          </div>

                          <div className="post-footer">
                            {/* <div
                              className="post-user"
                              style={{ visibility: "hidden", opacity: 0 }}
                            >
                              <img src={DrImg} alt="" />
                              <p>By Glenda Glover</p>
                            </div> */}

                            <p className="location">
                              LOCATION: {agd.Activity_Location[0]}{" "}
                            </p>

                            <div className="post-btn">
                              {/* <a
                                target="_blank"
                                href={`https://events.aka1908.net/22863000/${this.state.userTokenMain}?redirect=recordings`}
                                className="btn btn-green more-info"
                              >
                                WATCH RECORDING
                              </a> */}
                              {/* <button
                                className="btn btn-green more-info"
                                onClick={() => this.openPopupHandler(agd)}
                              >
                                MORE INFO
                              </button> */}
                              {agd.Activity_Type[0] == 'IN-PERSON' ? (
                                <a
                                  // target="_blank"
                                  // href={agd.Activity_Type[0]}
                                  className="btn btn-green"
                                  style={{ border: "none", padding: "0px" }}
                                >
                                  IN PERSON ONLY
                                </a>
                              ) : null}
                              {agd.Activity_Type[0] == 'VIRTUAL' ? (
                                <a
                                  target="_blank"
                                  href={agd.Activity_Type[0]}
                                  className="btn btn-green fill"
                                >
                                  VIRTUAL ACCESS
                                </a>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <h3>No events that match your selection.</h3>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Agenda;
