import React from "react";
import BtnClose from "../../assets/images/btn-close.svg";
import timeIcon from "../../assets/images/agenda/time.svg";
import DrImg from "../../assets/images/agenda/dr-img.png";
import "./agendaPopup.scss";

export const AgendaPopup = (props) => {
  // const [userDropdown, setuserDropdown] = useState(false);
  // console.log(props);
  return (
    <div
      className={
        props.popupToggle
          ? "aka-popup agenda-popup active"
          : "aka-popup agenda-popup"
      }
    >
      {props.selectedAgenda ? (
        <>
          <div className="popup-inner">
            <div className="ag-popup-header">
              <h2>{props.selectedAgenda.Activity_Name[0]}</h2>
              <button
                className="close"
                type="button"
                onClick={props.closeHandler}
              >
                <img src={BtnClose} alt="" />
              </button>

              <div className="user-detail flex space-between">
                {/* <div className="post-user">
                  <img src={DrImg} alt="" />
                  <p>By Glenda Glover</p>
                </div> */}
                <div className="post-register">
                  <p className="time-txt">
                    {props.selectedAgenda.Activity_Start[0]} to{" "}
                    {props.selectedAgenda.Activity_End[0]}{" "}
                    <img src={timeIcon} alt="" />
                  </p>
                  {props.selectedAgenda.openEvents ? (
                    <p className="register-txt open-txt">
                      {props.selectedAgenda.Activity_Location[0]}
                    </p>
                  ) : (
                    <p className="register-txt">Registered Event</p>
                  )}
                </div>
              </div>
            </div>

            <div className="ag-popup-body">
              <p>{props.selectedAgenda.Activity_Description[0]}</p>
              {props.selectedAgenda.Activity_Code_1 ? (
                <div className="btn-wrapper">
                  <a
                    target="_blank"
                    href={props.selectedAgenda.Activity_Code_1[0]}
                    className="btn btn-green fill"
                  >
                    GO TO EVENT
                  </a>
                </div>
              ) : null}
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
};
