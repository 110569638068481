import React, { Component } from "react";
import "./App.scss";
import $ from "jquery";
import Login from "./containers/login/login";
import Home from "./containers/home/home";
import Agenda from "./containers/agenda/agenda";
import FaqPage from "./containers/faq/faq";
import Highlight from "./containers/highlight/highlight";
import { Route, Router, Switch } from "react-router";
import { Redirect } from "react-router-dom";
import NotFound from "./containers/notFound/notFound";
import AOS from "aos";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import Layout from "./hoc/layout/layout";
import Hopinredirect from "./containers/hopinredirect/hopinredirect";
import Vfairsredirect from "./containers/vfairsredirect/vfairsredirect";
const hasWindow = typeof window !== "undefined" ? true : false;
if (hasWindow) {
  window.loader = true;
}

class App extends Component {
  state = {
    loader: true,
  };
  componentDidMount() {
    console.log("Router change");
    if (hasWindow) {
      // $(document).ready(function () {
      //   var scene = document.getElementById("scene");
      //   if (scene) {
      //     var parallaxInstance = new Parallax(scene);
      //   }

      //   // parallaxInstance.friction(0, 1.3);
      // });

      $(window).bind("load", function () {
        if (document.readyState === "complete") {
          setTimeout(function () {
            $(".loader-box").fadeOut(500);
          }, 0);
          /* AOS */
          AOS.init({
            duration: 1000,
            once: true,
          });
        }
      });

      var interval = setInterval(() => {
        if (document.readyState === "complete") {
          clearInterval(interval);
          setTimeout(() => {
            this.setState({
              loader: false,
            });

            // AOS.init({
            //   duration: 1000,
            //   offset: 60,
            //   once: true,
            // });
          }, 1000);
        }
      }, 100);

      // $(function () {
      //   $(".lazy").lazy();
      // });

      $(document).ready(function () {
        //agenda page js

        $(".agenda-section .tab-link a").click(function (e) {
          e.preventDefault();
          var tabId = $(this).attr("href");
          $(".agenda-section .tab-link a").removeClass("active");
          $(this).addClass("active");
          $(".section-tab .tab-content").removeClass("active");
          $(".section-tab").find(tabId).addClass("active");
        });

        $(".agenda-section .left-tab-link a").click(function (e) {
          e.preventDefault();
          var leftTabId = $(this).attr("href");
          $(this).parents(".left-tab-link").find("a").removeClass("active");
          $(this).addClass("active");
          $(this)
            .parents(".tab-content")
            .find(".left-tab-content")
            .removeClass("active");
          $(".section-tab").find(leftTabId).addClass("active");
        });
        // $(".select-box").change(function () {
        //   var dropdown = $(this).val();
        //   $(this)
        //     .parents(".tab-content")
        //     .find(".left-tab-content")
        //     .removeClass("active");
        //   $(".section-tab").find(dropdown).addClass("active");
        // });

        //highlight page
        $(".highlight-colm-box .read-more").click(function (e) {
          e.preventDefault();
          $(".highlight-popup").addClass("active");
        });
        $(".aka-popup .close").click(function (e) {
          e.preventDefault();
          $(this).parents(".aka-popup").removeClass("active");
        });

        //agenda popup
        $(".post-box .more-info").click(function (e) {
          e.preventDefault();
          $(".agenda-popup").addClass("active");
        });

        $(window).scroll(function () {
          var topHeight = $(window).scrollTop();
          if (topHeight > 100) {
            $(
              ".agenda-section .tab-link, .agenda-section .tab-sec-header"
            ).addClass("active");
          } else {
            $(
              ".agenda-section .tab-link, .agenda-section .tab-sec-header"
            ).removeClass("active");
          }
        });
        $(".auditorium").click(function (e) {
          e.preventDefault();
          // console.log("click");
          $(".auditorium-popup").addClass("active");
        });
        $(".auditorium-popup .close,.auditorium-popup .stay-here").click(
          function (e) {
            e.preventDefault();
            $(".auditorium-popup").removeClass("active");
          }
        );

        /*-----------------lobby hall popup------------------*/
        $(".lobby-hall").click(function (e) {
          e.preventDefault();
          $(".hall-popup").addClass("active");
        });
        $(".hall-popup .close,.hall-popup .stay-here").click(function (e) {
          e.preventDefault();
          $(".hall-popup").removeClass("active");
        });
      });
    }
  }

  render() {
    const { location } = this.props;
    let redirect = "";
    let getToken = localStorage.getItem("userToken");
    // console.log(getToken);
    if (!getToken) {
      redirect = <Redirect to="/login" />;
    }

    return (
      <>
        {redirect}
        <div
          id="overlay"
          className={
            this.state.loader ? "loader mainLoader" : "loader mainLoader active"
          }
        >
          <div className="inner-box">
            <div className="loader-circle"></div>
            <div className="loader-line-mask">
              <div className="loader-line"></div>
            </div>
          </div>
        </div>
        <Layout locatKey={location.pathname.split("/")[1]}>
          <div className="box-site-main">
            <TransitionGroup>
              <CSSTransition timeout={250} classNames="fade" key={location.key}>
                <Switch location={location}>
                  <Route path="/" exact component={Home} />
                  <Route
                    path="/hopinredirect"
                    exact
                    component={Hopinredirect}
                  />
                  <Route
                    path="/vfairsredirect"
                    exact
                    component={Vfairsredirect}
                  />

                  <Route path="/agenda" exact component={Agenda} />
                  <Route path="/faq" exact component={FaqPage} />
                  <Route path="/highlight" exact component={Highlight} />
                  <Route path="/login" exact component={Login} />
                  <Route component={NotFound} />
                </Switch>
              </CSSTransition>
            </TransitionGroup>
          </div>
        </Layout>
      </>
    );
  }
}

export default App;
