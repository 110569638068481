import React from "react";
import BtnClose from "../../assets/images/btn-close.svg";
import aarp_logo from "../../assets/images/login/aarp_logo@3x.png";
import american_medical_association from "../../assets/images/login/american_medical_association@3x.png";
import american_red_cross from "../../assets/images/login/american_red_cross@3x.png";
import fuji from "../../assets/images/login/fuji@3x.png";
import geLogo from "../../assets/images/login/ge-logo.png";
import national_medical_asociation from "../../assets/images/login/national_medical_asociation@3x.png";
import kauffman_foundation from "../../assets/images/login/kauffman_foundation@3x.png";
import lifelock from "../../assets/images/login/lifelock@3x.png";
import lions_club from "../../assets/images/login/lions_club@3x.png";
import S4S from "../../assets/images/login/S4S@3x.png";
import SNI_Logo from "../../assets/images/login/SNI_Logo@3x.png";
import susan_g_komen from "../../assets/images/login/susan_g_komen-logo@3x.png";

import platinumOne from "../../assets/images/platinum-1.png";
import platinumTwo from "../../assets/images/platinum-2.png";
import platinumThree from "../../assets/images/platinum-3.jpg";
import emeraldOne from "../../assets/images/emerald-1.png";
import emeraldTwo from "../../assets/images/emerald-2.png";
import emeraldThree from "../../assets/images/emerald-3.png";

import pearlOne from "../../assets/images/pearl-1.png";
import pearlTwo from "../../assets/images/pearl-2.png";
import pearlThree from "../../assets/images/pearl-3.png";

import silverOne from "../../assets/images/silver-1.png";
import silverTwo from "../../assets/images/silver-2.png";
import newLogo from "../../assets/images/irelaunch-logo.png";
import "./sponserPopup.scss";

export const SponserPopup = (props) => {
  // const [userDropdown, setuserDropdown] = useState(false);

  return (
    <div
      className={props.sponserToggle ? "sponser-popup active" : "sponser-popup"}
    >
      <div className="sponser-sec">
        <button type="button" className="close" onClick={props.sponserHandler}>
          <img src={BtnClose} alt="" />
        </button>

        <div className="colm">
          <h6>DIAMOND SPONSORS</h6>
          <ul>
            <li>
              <img src={platinumOne} alt="" />
            </li>
          </ul>
        </div>

        <div className="colm">
          <h6>EMERALD SPONSORS</h6>
          <ul>
            <li>
              <img src={emeraldOne} alt="" />
            </li>
            <li>
              <img src={emeraldTwo} alt="" />
            </li>
            <li>
              <img src={emeraldThree} alt="" />
            </li>
          </ul>
        </div>

        <div className="colm">
          <h6>PEARL SPONSORS</h6>
          <ul>
            <li>
              <img src={pearlOne} alt="" />
            </li>
            <li>
              <img src={pearlTwo} alt="" />
            </li>
            <li>
              <img src={pearlThree} alt="" />
            </li>
          </ul>
        </div>

        <div className="colm">
          <h6>GOLD SPONSORS</h6>
          <ul>
            <li>
              <img src={silverOne} alt="" />
            </li>
            <li>
              <img src={silverTwo} alt="" />
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
