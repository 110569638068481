import React from "react";
import BtnClose from "../../assets/images/btn-close.svg";
import "./lobbyHallPopup.scss";

export const LobbyHallPopup = (props) => {
  // const [userDropdown, setuserDropdown] = useState(false);
  let takeButton = () => {
    window.open(props.vfairUrl, "_blank");
    props.lobbyHallClose();
  };
  return (
    <div
      className={
        props.lobbyHallToggle
          ? "aka-popup popup-mini hall-popup active"
          : "aka-popup popup-mini hall-popup"
      }
    >
      <div className="popup-inner">
        <button className="close" type="button" onClick={props.lobbyHallClose}>
          <img src={BtnClose} alt="" />
        </button>
        <div className="custom-txt-box">
          <h2>
            Let's visit the Convention<br />Center
          </h2>
          <p>
            By proceeding we will open a new tab with
            <br /> the access to the Convention Center
            <br />
            See you there!
          </p>

          <p className="count">{props.timer}s</p>

          <div className="btn-wrapper">
            {/* <a
              href={props.vfairUrl}
              target="_blank"
              className="btn btn-green fill"
            >
              Take me there
						</a> */}
            <button
              type="button"
              onClick={takeButton}
              className="btn btn-pink-fill fill"
            >
              Take me there
            </button>

            <button onClick={props.lobbyHallClose} className="stay-here">
              I'd rather stay here
            </button>
            {/* <a className="stay-here" href="#">
              I'd rather stay here
            </a> */}
          </div>
        </div>
      </div>
    </div>
  );
};
