import React from "react";
import BtnClose from "../../assets/images/btn-close.svg";

export const WelcomePopup = (props) => {
  // const [userDropdown, setuserDropdown] = useState(false);

  return (
    <div
      className={
        props.welcomeToggle
          ? "aka-popup welcome-popup active"
          : "aka-popup welcome-popup"
      }
    >
      <div className="popup-inner">
        <button type="button" onClick={props.welcomeHandler} className="close">
          <img src={BtnClose} alt="" />
        </button>
        <div className="custom-video-box">
          <iframe
            className="welcome-video"
            src="https://player.vimeo.com/video/724961306?h=b84ef66264"
            width="640"
            height="360"
            frameborder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowfullscreen>
          </iframe>
        </div>
        <div className="check-box">
          <input type="checkbox" onChange={props.checkHandler} id="checkBox" />
          <label for="checkBox">Don't show me next time</label>
        </div>
      </div>
    </div>
  );
};
