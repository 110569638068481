import React, { Component } from "react";
import Layout from "../../hoc/layout/layout";
import "./faq.scss";
import BannerImg from "../../assets/images/faq/aka-07121802-17919@3x.png";
import FaqContent from "./faq.json";

class FaqPage extends Component {
  render() {
    return (
      <>
        {/* <div className="loader-box"></div> */}
        <div className="innerpage-sec faq-section">
          <div className="overlay">
            <img src={BannerImg} alt="" />
          </div>

          <div className="section-header">
            <div className="inner">
              <h1>FAQs</h1>
              <p>
                The information below answers frequently asked questions about the 70th Boule.
              </p>
              <ul>
                <li>
                  <a className="btn btn-green more-info" href="#post">
                    Post event Questions
                  </a>
                </li>
                <li>
                  <a className="btn btn-green more-info" href="#live">
                    Live event Questions
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div id="post" className="heading-box">
            <h2>Post Event Questions</h2>
          </div>

          <div className="faq-box">
            <h5>Where can I get my Certificate of Attendance for the event?</h5>
            <p>
              Your 69th Boule Part II/2021 Leadership Seminar Certificate of
              Attendance can be found on your Members Only profile. Navigate to
              My Events and Workshops. Click on 2021 Boule/LS and click the
              View/Print Attendance Certificate button.
              <br />
              You may also find your certificate on the registration site at
              <a href={`https://events.aka1908.net/`} target="_blank">
                https://events.aka1908.net
              </a>
              . Go to the My Account page and click the View/Print Attendance
              Certificate button.
            </p>
          </div>
          <div className="faq-box">
            <h5>Where can I get my certification certificates?</h5>
            <p>
              Your certification certificates can be found on your Members Only
              events profile. Navigate to My Certifications. Click the
              View/Print Attendance Certificate link.
            </p>
          </div>
          <div className="faq-box">
            <h5>How can I see the recordings of the activities?</h5>
            <p>
              Use the Auditorium & Convention Center links to see the list of
              recordings for both the Boule and Leadership Seminar activities.
            </p>
          </div>
          <div className="faq-box">
            <h5>Why aren’t all the recordings available?</h5>
            <p>
              Some activities were done outside of the Hopin platform. Thus the
              recordings are not available. Additionally, due to contractual
              agreements, recordings of some activities with special celebrity
              guests could not be posted.
            </p>
          </div>
          <div className="faq-box">
            <h5>
              I was unable or had a hard time connecting to the platform. Who
              should I contact?
            </h5>
            <p>
              Please contact the office of the Executive Director at
              <a href="mailto:exec@aka1908.com">exec@aka1908.com</a>.
            </p>
          </div>
          {/* <div className="main-faq-box"> */}
          <div id="live" className="heading-box">
            <h2>Live Event Questions</h2>
          </div>
          {/* {FaqContent.content.map((data, key) => (
              <div key={key} className="faq-box">
                <h5>{data.title}</h5>
                <p dangerouslySetInnerHTML={{ __html: data.description }}></p>
                {data.note_text ? (
                  <p>
                    <b dangerouslySetInnerHTML={{ __html: data.note_text }}></b>
                  </p>
                ) : null}
              </div>
            ))}
          </div> */}

          <div className="faq-box">
            <h5>What internet browser should I use?</h5>
            <p>
              For the best experience using VFairs, we recommend using the latest version of Chrome, Firefox, Safari or Edge. The VFairs app also runs on mobile web browsers. Other browsers may not have the latest web technologies necessary for online events to run in a web browser.
            </p>
            <p>
              For additional support regarding your browser, visit{""}
              <a href={`https://start.VFairs.com/en/check-browser/`} target="_blank">
                https://start.VFairs.com/en/check-browser
              </a>
              .
            </p>
          </div>

          <div className="faq-box">
            <h5>Can I customize my settings?</h5>
            <p>
              Visit and complete your profile on VFairs. You can include a short bio, customize your avatar/profile picture, add social media profiles and websites. This information will be shared if you choose to exchange information with a soror or vendor.
            </p>
          </div>

          <div className="faq-box">
            <h5>How do I know what is happening?</h5>
            <p>
              The Auditorium will display the schedule. Navigate the schedule by using filters to find plenary sessions, forums, and other special activities.
            </p>
          </div>

          <div className="faq-box">
            <h5>How do I attend an event?</h5>
            <p>
              All sessions, forums, activities will take place in the Auditorium in the Virtual Convention Center. You can access the convention center by clicking the links on the homepage or via the Agenda tab. Once in the Virtual Convention Center lobby, click the Auditorium to access all activities.
            </p>
          </div>

          <div className="faq-box">
            <h5>What is the suggested daily attire?</h5>
            <p>Monday, July 11 is Pink and Green.</p>
            <p>
              Tuesday, July 12 is Scarf Day. Scarves will be available for purchase in the AKA booth in the vendor area.
            </p>
            <p>Wednesday, July 13 is Green Day.</p>
            <p>Thursday, July 15 White Day. Uninterrupted White for Plenary Session 6. The attire for the Closing Gala is pink cocktail.</p>
          </div>

          <div className="faq-box">
            <h5>How do Delegates vote?</h5>
            <p>
              Virtual attendees are not able to serve as delegates.
            </p>
          </div>

          <div className="faq-box">
            <h5>What do I do if I want to speak from the floor</h5>
            <p>
              Virtual attendees will not be able to speak from the floor.
            </p>
          </div>

          <div className="faq-box">
            <h5>How do I engage with sorors?</h5>
            <p>
              There are several ways to engage sorors in the VFairs platform.
            </p>
            <p>
              Within the Virtual Convention Center, access the Lounge to enter chat rooms and engage with other sorors.
            </p>
            <p>
              Take a photo in the virtual Photo Booth. Add frames, stickers, filters to showcase your Boule spirit. Share the photo within the conference and on your social media.
            </p>
            <p>
              From the VFairs app, post to our internal 70th Boule social media platform.
            </p>
          </div>

          <div className="faq-box">
            <h5>How do I shop for vendors?</h5>
            <p>
              Virtual attendees can access vendors in the 70th Boule Virtual Convention Center on the VFairs platform.
            </p>
          </div>

          <div className="faq-box">
            <h5>I am looking for a specific vendor and don’t see them?</h5>
            <p>
              Each exhibit hall will have a listing of vendors located in that hall. A complete directory is included in the Agenda Book and on the platform.
            </p>
            <p>
              There is a search bar on the Exhibitor Hall lobby page above the navigation bar which will take you directly to a specific vendor’s booth. Please note that all AKA-licensed vendors do not have booths for this combined conference.            </p>
            <p>
              Not all vendors opted into the virtual exhibit hall.
            </p>
          </div>

          <div className="faq-box">
            <h5>
              Can I purchase items in the Exhibit Hall through the platform?
            </h5>
            <p>
              All vendors will link to their online store, and attendees can make purchases from their website. Please check with the vendor to see preferred payment methods.
            </p>
          </div>

          <div className="faq-box">
            <h5>
              Will the sessions be recorded or archived for future access?
            </h5>
            <p>
              Plenary sessions will not be recorded. Other activities that are streamed will be recorded for archival purposes. These activities include:

              <ul>
                <li><p>Diamond, Pearl, Golden, and Silver Luncheon</p></li>
                <li><p>Social Justice Forum</p></li>
                <li><p>Voting Rights Forum</p></li>
                <li><p>Closing Gala/Office Installation</p></li>
              </ul>
            </p>

            <p>
              If you miss one of these activities, you will be able to view the recording when made available later.
            </p>
          </div>


          <div className="faq-box">
            <h5>Who do I contact for assistance?</h5>
            <p>
              For assistance with your Membership Profile, contact
              <a href="mailto:members@aka1908.com">members@aka1908.com</a>.
            </p>
            <p>
              For assistance accessing the virtual Convention Center or general Boule help, click on the Help Desk/Soror Services.
            </p>
            <p>
              For assistance with the virtual Convention Center, select Info Desk from the navigation bar or lobby.
            </p>
            <p>
              The help desks are staffed during conference hours.
            </p>
          </div>

          {/* <div className="faq-box">
            <h5>Is there a vendor directory?</h5>
            <p>
              Each exhibit hall will have a listing of vendors located in that
              hall. A complete directory is included in the Agenda Book.
            </p>
          </div>

          <div className="faq-box">
            <h5>
              How do I get to the Convention Center from the Exhibitor Hall?
            </h5>
            <p>XYZ</p>
          </div>
          <div className="faq-box">
            <h5>How can I navigate the Auditorium and Convention Center?</h5>
            <p>
              Visit and complete your profile. You can include a short bio,
              customize your avatar/profile picture, add social media profiles
              and websites. This information will be shared if you choose to
              exchange information with a soror.
            </p>
            <p>
              Additionally, you can view the sessions included with your
              registration.
            </p>
          </div>
          <div className="faq-box">
            <h5>Will we see Delegate voting results in real time? </h5>
            <p>
              No. The Supreme Basileus will review and announce the results at a
              later time during the meeting.
            </p>
          </div>
          <div className="faq-box">
            <h5>When do Delegates vote? </h5>
            <p>
              Voting takes place in real time during Plenary Session 1 on
              Wednesday, July 14 and Plenary Session 3 on Thursday, July 15.
            </p>
          </div>

          <div className="faq-box">
            <h5>
              Who do we contact if we have issues during the Plenary Session?
            </h5>
            <p>
              For assistance, please click on the Soror Services icon or email
              XYZ.
            </p>
          </div>


          <div className="faq-box">
            <h5>Who can speak during the Plenary Sessions? </h5>
            <p>Everyone can speak on the floor.</p>
          </div>


          <div className="faq-box">
            <h5>Where are my events happening?</h5>
            <p>
              Use the Reception tab to access the Schedule-At-A-Glance. There
              are several places you can access your personalized agenda.
            </p>
          </div>


          <div className="faq-box">
            <h5>How will I get credit for attending the session?</h5>
            <p>
              Hopin will provide a detailed report of your attendance throughout
              the event. You can expect to see the record of your attendance
              reflected on your Membership Profile XYZ.
            </p>
            <p>
              If you have a dispute about attendance, please contact
              <a href="mailto:XYZ@aka1908.com">XYZ@aka1908.com</a> .
            </p>
          </div>

          <div className="faq-box">
            <h5>Who can I network with?</h5>
            <p>
              Networking is arranged at random but can be set to a specific
              ticket type to segment the matchmaking between groups of ticket
              holders.
            </p>
          </div>
          <div className="faq-box">
            <h5>I made so many connections, how do I retrieve my contacts?</h5>
            <p>
              Click on your profile. Click on the Connections tab to retrieve
              your connections and download their contact information.
            </p>
          </div> */}

        </div>
      </>
    );
  }
}

export default FaqPage;
