import React, { Component } from "react";

class Hopinredirect extends Component {
  componentDidMount() {
    let getHopinUrl = localStorage.getItem("hopinUrl");
    if (getHopinUrl) {
      window.location.href = getHopinUrl;
    }
  }
  render() {
    return <></>;
  }
}
export default Hopinredirect;
