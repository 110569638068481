import React, { Component } from "react";
import Layout from "../../hoc/layout/layout";
import BannerImg from "../../assets/images/highlight/bannerImage.png";
import Highlight1 from "../../assets/images/highlight/highlight-1-new.png";
import Highlight2 from "../../assets/images/highlight/highlight-2-new.png";
import Highlight3 from "../../assets/images/highlight/highlight-3-new.png";
import Highlight4 from "../../assets/images/highlight/highlight-4-new.png";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";

import { HighlightPopup } from "../../components/highlightPopup/highlightPopup";
import { LazyLoadImage } from "react-lazy-load-image-component";
import pinwheel from "../../assets/images/pinwheel.gif";
// import ImageLoader from "react-load-image";
import $ from "jquery";

const hasWindow = typeof window !== "undefined" ? true : false;
if (hasWindow) {
  window.loader = true;
}

class Highlight extends Component {
  state = {
    userTokenMain: "",
    allhightlights: [
      {
        image: Highlight1,
        title:
          "Coming Soon",
        subtitle:
          "Coming Soon",
        description:
          "Coming Soon",
        full_description:
          "Coming Soon",
      },
      {
        image: Highlight2,
        title:
          "Coming Soon",
        subtitle:
          "Coming Soon",
        description:
          "Coming Soon",
        full_description:
          "Coming Soon",
      },
      {
        image: Highlight3,
        title:
          "Coming Soon",
        subtitle:
          "Coming Soon",
        description:
          "Coming Soon",
        full_description:
          "Coming Soon",
      },
      {
        image: Highlight4,
        title:
          "Coming Soon",
        subtitle:
          "Coming Soon",
        description:
          "Coming Soon",
        full_description:
          "Coming Soon",
      },

    ],
    selectedHighlight: "",
    popupToggle: false,
  };
  popupHandler = (data) => {
    this.setState({
      selectedHighlight: data,
      popupToggle: true,
    });
  };

  componentDidMount() {
    if (hasWindow) {
      $(".highlight-section .highlight-colm-box").click(function () {
        $("body").addClass("active");
      });
      $(".main-close").click(function () {
        $("body").removeClass("active");
      });
    }
    let userToken = localStorage.getItem("userToken");
    this.setState({
      userTokenMain: userToken,
    });
  }

  render() {
    return (
      <div>
        {/* <div className="loader-box"></div> */}
        <HighlightPopup
          popupHandler={() => this.setState({ popupToggle: false })}
          selectedHighlight={this.state.selectedHighlight}
          popupToggle={this.state.popupToggle}
        />
        <div className="innerpage-sec highlight-section">
          <div className="overlay">
            <img src={BannerImg} alt="" />
          </div>

          <div className="section-header">
            <div className="inner">
              <h1>HIGHLIGHTS</h1>
              <p>
                Click on any of the featured events below to learn more.
              </p>
              {/* <a
                href={`https://events.aka1908.net/21991000/${this.state.userTokenMain}?redirect=recordings`}
                className="btn btn-green more-info"
                target="_blank"
              >
                WATCH RECORDINGS
              </a> */}
            </div>
          </div>

          {/* <div className="colm-row">
            {this.state.allhightlights.length > 0
              ? this.state.allhightlights.map((data, key) => (
                  <div
                    key={key}
                    onClick={() => this.popupHandler(data)}
                    className="highlight-colm-box"
                  >
                    <div className="inner-colm">
                      <a href="#" className="read-more">
                        <figure className="custom-img-box">
                          {data.image ? (
                            <img src={data.image} alt="" />
                          ) : (
                            <img src={pinwheel} alt="" />
                          )}
                        </figure>
                      </a>
                      <div className="custom-txt-box">
                        <h4 className="title">{data.title}</h4>
                        <p className="subtitle">{data.subtitle}</p>
                        <p>{data.description}</p>
                      </div>
                    </div>
                  </div>
                ))
              : null}
          </div> */}
          <div className="colm-row"></div>
          <ResponsiveMasonry
            columnsCountBreakPoints={{ 270: 1, 480: 2, 1190: 2, 1200: 3 }}
          >
            <Masonry gutter={15}>
              {this.state.allhightlights.length > 0
                ? this.state.allhightlights.map((data, key) => (
                  <div
                    key={key}
                    onClick={() => this.popupHandler(data)}
                    className="highlight-colm-box"
                  >
                    <div className="inner-colm">
                      <figure className="custom-img-box">
                        {data.image ? (
                          <img src={data.image} alt="" />
                        ) : (
                          <img src={pinwheel} alt="" />
                        )}
                      </figure>

                      <div className="custom-txt-box">
                        <h4 className="title">{data.title}</h4>
                        <p className="subtitle">{data.subtitle}</p>
                        <p>{data.description}</p>
                      </div>
                    </div>
                  </div>
                ))
                : null}
            </Masonry>
          </ResponsiveMasonry>
        </div>
      </div>
    );
  }
}

export default Highlight;
