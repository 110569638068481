import React, { Component } from "react";
import axios from "axios";

class Vfairsredirect extends Component {
  componentDidMount() {
    let email_address = localStorage.getItem("email_address");
    if (email_address) {
      axios
        .get(
          // `https://api.vfairs.com/v12/users?access_key=4hyr4hFaaFOGDgN9wyaKF22FKRsKzku6fkaN9&username=${email_address}`
          `https://api.vfairs.com/v12/users?access_key=wC9I1SqMbfSVJJOwLlm0MkkUI0dU4InQFOzVx`
        )
        .then((res) => {
          if (res.data) {
            window.location.href = res.data[0].autologin_url;
          }
        });
    }
  }
  render() {
    return <></>;
  }
}
export default Vfairsredirect;
