import React from "react";
import BtnClose from "../../assets/images/btn-close.svg";

export const ConfirmPopup = (props) => {
  return (
    <div
      className={props.openconfirm ? "confirm-popup active" : "confirm-popup"}
    >
      <div className="confirm-sec">
        <div>
          <h4>Are you sure you want to sign out?</h4>
        </div>
        <div className="btn-wrapper">
          <input
            className="btn btn-green"
            id="confirmYes"
            type="button"
            value="Yes"
            onClick={props.okHandler}
          />
          <input
            className="btn"
            id="confirmNo"
            type="button"
            value="No"
            onClick={props.closeConfirm}
          />
        </div>
      </div>
    </div>
  );
};
