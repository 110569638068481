import React, { Component } from "react";
import "./login.scss";
import FBIcon from "../../assets/images/login-FB.svg";
import InstaIcon from "../../assets/images/login-Insta.svg";
import YTIcon from "../../assets/images/login-YT.svg";
import leadership from "../../assets/images/login-logo.png";

import platinumOne from "../../assets/images/platinum-1.png";
import platinumTwo from "../../assets/images/platinum-2.png";
import platinumThree from "../../assets/images/platinum-3.jpg";
import emeraldOne from "../../assets/images/emerald-1.png";
import emeraldTwo from "../../assets/images/emerald-2.png";
import emeraldThree from "../../assets/images/emerald-3.png";

import goldOne from "../../assets/images/gold-1.png";
import goldTwo from "../../assets/images/gold-2.jpg";
import goldThree from "../../assets/images/gold-3.png";

import silverOne from "../../assets/images/silver-1.png";
import silverTwo from "../../assets/images/silver-2.png";
import silverThree from "../../assets/images/silver-3.png";
import { HelpdeskPopup } from "../../components/helpdeskPopup/helpdeskPopup";
import axios from "axios";
const xml2js = require("xml2js");
class Login extends Component {
  state = {
    helpdeskToggle: false,
    loader: true,
  };

  componentDidMount() {
    localStorage.removeItem("open_welcome_popup");
    // console.log(this.props.location.search.split("?id="));
    var userToken = this.props.location.search.split("?id=");
    console.log("Login Screen Mounted");
    console.log(userToken[1]);
    let xmls = `<?xml version="1.0" encoding="utf-8"?>\
			<soap:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/">\
			 <soap:Body>\
				 <getSororRegistration xmlns="https://aka.sdsreg.com/WS/">\
					 <token>I9^zLe59zL1sjRnRs81Ie7g5XLq1!tv0TB2LmOc2</token>\
					 <showCode>22863000</showCode>\
					 <memberKey>${userToken[1]}</memberKey>\
					 <memberID></memberID>\
					 <postalCode></postalCode>\
				 </getSororRegistration>\
			 </soap:Body>\
			</soap:Envelope>`;

    axios
      .post("https://aka.sdsreg.com/WS/PROD/service.asmx", xmls, {
        headers: { "Content-Type": "text/xml" },
      })
      .then((res) => {
        let data = res.data;
        console.log(res);
        xml2js.parseString(data, (err, result) => {
          if (err) {
            throw err;
          }

          // `result` is a JavaScript object
          // convert it to a JSON string
          const json = JSON.stringify(result, null, 4);

          // log JSON string
          let mainFormatedData = JSON.parse(json);
          // let mainData =
          //   mainFormatedData["soap:Envelope"]["soap:Body"][0]
          //     .getSororActivitiesResponse[0].getSororActivitiesResult[0][
          //     "diffgr:diffgram"
          //   ][0].DocumentElement[0].AKA;

          console.log(mainFormatedData);

          let mainData =
            mainFormatedData["soap:Envelope"]["soap:Body"][0]
              .getSororRegistrationResponse[0].getSororRegistrationResult;

          console.log(mainData)

          if (mainData) {
            console.log(
              mainData[0]["diffgr:diffgram"][0].DocumentElement[0].AKA[0]
            );
            localStorage.setItem("userToken", userToken[1]);
            localStorage.setItem(
              "fullName",
              mainData[0]["diffgr:diffgram"][0].DocumentElement[0].AKA[0]
                .Full_Name[0]
            );
            localStorage.setItem(
              "hopinUrl",
              mainData[0]["diffgr:diffgram"][0].DocumentElement[0].AKA[0]
                .Registration_Code_1[0]
            );
            localStorage.setItem(
              "email_address",
              mainData[0]["diffgr:diffgram"][0].DocumentElement[0].AKA[0]
                .Email_Address[0]
            );

            localStorage.setItem("open_welcome_popup", true);

            window.location.href = "/";
          } else {
            this.setState({
              loader: false,
            });
          }
          // this.setState({
          //   agenda: fullData,
          // });
        });
      }, (error) => {
        console.log("------ERROR FROM LOGIN JS-----------");
        console.log(error);
      });
  }
  helpdeskHandler = () => {
    this.setState((prevState) => ({
      helpdeskToggle: !prevState.helpdeskToggle,
    }));
  };

  render() {
    return (
      <div className="login-wrap">
        <div
          id="overlay"
          className={this.state.loader ? "loader" : "loader active"}
        >
          <div className="inner-box">
            <div className="loader-circle"></div>
            <div className="loader-line-mask">
              <div className="loader-line"></div>
            </div>
          </div>
        </div>
        {/* <div className="loader-box"></div> */}
        <HelpdeskPopup
          helpdeskHandler={this.helpdeskHandler}
          helpdeskToggle={this.state.helpdeskToggle}
        />
        <div className="login-header">
          <div className="social-sec">
            <ul className="flex">
              <li>
                <a
                  href="https://www.facebook.com/akasorority1908/"
                  target="_blank"
                >
                  <img src={FBIcon} />
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/akasorority1908/"
                  target="_blank"
                >
                  <img src={InstaIcon} />
                </a>
              </li>
              <li>
                <a
                  href="https://www.youtube.com/channel/UCIhtDU96BX6dqJd-3I14-pQ/featured"
                  target="_blank"
                >
                  <img src={YTIcon} />
                </a>
              </li>
            </ul>
          </div>

          <div className="logo-sec">
            <img src={leadership} alt="" />
          </div>
          <div className="help-sec">
            <button className="btn btn-green" onClick={this.helpdeskHandler}>
              Help Desk
            </button>
          </div>
        </div>

        <div className="forgot-sec">
          {/* <p>
            If you registered for the event, check the login link that was sent
            to your email. If you don't find it or need to retrieve it, please
            click the link below.
          </p> */}
          <a
            className="forget-link"
            target="_blank"
          >
            Lost your event access link?
          </a>
          <a
            href="https://events.aka1908.net/boule2022"
            target="_blank"
            className="btn lg btn-green fill"
          >
            RETRIEVE EVENT LINK
          </a>
          {/* <a
            style={{ opacity: 0, visibility: "hidden" }}
            className="forget-link"
            href="#">
            Forgot your event link?
          </a> */}
        </div>

        <div className="sponser-sec">
          <div className="colm">
            <h6>DIAMOND SPONSORS</h6>
            <ul>
              <li>
                <img src={platinumOne} alt="" />
              </li>
              <li>
                <img src={platinumTwo} alt="" />
              </li>
              <li>
                <img src={platinumThree} alt="" />
              </li>
            </ul>
          </div>

          <div className="colm">
            <h6>EMERALD SPONSORS</h6>
            <ul>
              <li>
                <img src={emeraldOne} alt="" />
              </li>
              <li>
                <img src={emeraldTwo} alt="" />
              </li>
              <li>
                <img src={emeraldThree} alt="" />
              </li>
            </ul>
          </div>

          <div className="colm">
            <h6>PEARL SPONSORS</h6>
            <ul>
              <li>
                <img src={goldOne} alt="" />
              </li>
              <li>
                <img src={goldTwo} alt="" />
              </li>
              <li>
                <img src={goldThree} alt="" />
              </li>
            </ul>
          </div>

          <div className="colm">
            <h6>GOLD SPONSORS</h6>
            <ul>
              <li>
                <img src={silverOne} alt="" />
              </li>
              <li>
                <img src={silverTwo} alt="" />
              </li>
              <li>
                <img src={silverThree} alt="" />
              </li>
            </ul>
          </div>
        </div>

        <div>
          <p className="copyright-txt">Copyright 2022</p>
        </div>
      </div>
    );
  }
}

export default Login;
