import React, { Component } from "react";
import Logo from "../../assets/images/logo-new.png";
import FBIcon from "../../assets/images/FB.svg";
import InstaIcon from "../../assets/images/Insta.svg";
import YTIcon from "../../assets/images/YT.svg";
import { Link, NavLink } from "react-router-dom";
import $ from "jquery";
import "./sidebar.scss";

const hasWindow = typeof window !== "undefined" ? true : false;
if (hasWindow) {
  window.loader = true;
}
class Sidebar extends Component {
  state = {
    loader: true,
    fullName: "",
    hopinUrl: "",
    userTokenMain: "",
  };
  componentDidMount() {
    // console.log("route change");
    let fullName = localStorage.getItem("fullName");
    let getHopinUrl = localStorage.getItem("hopinUrl");
    let userToken = localStorage.getItem("userToken");

    this.setState({
      userTokenMain: userToken,
      hopinUrl: getHopinUrl,
    });

    this.setState({
      fullName: fullName,
    });
    if (hasWindow) {
      $(document).ready(function () {
        $(".toggle-btn").click(function (e) {
          e.preventDefault();
          $(this).toggleClass("active");
          $("body").toggleClass("active");
          $(".side-nav .outer-side-nav").toggleClass("active");
        });

        $(".outer-side-nav").click(function () {
          $(".toggle-btn").removeClass("active");
          $("body").removeClass("active");
          $(".side-nav .outer-side-nav").removeClass("active");
        });

        $(".sponsors-btn").click(function () {
          setTimeout(function () {
            $("body").addClass("active");
          }, 100);

          console.log("working");
        });
        $(".sponser-popup .close").click(function () {
          $("body").removeClass("active");
        });
      });

      // $(window).bind("load", function () {
      //   if (document.readyState === "complete") {
      //     setTimeout(function () {
      //       $(".loader-box").fadeOut(500);
      //     }, 0);
      //     /* AOS */
      //   }
      // });
    }
  }
  render() {
    return (
      <>
        <div className="side-nav">
          {/* <div
          className={this.state.loader ? "loader-box" : "loader-box active"}
        ></div> */}

          <div className="logo-box">
            <Link to="/">
              <img src={Logo} />
            </Link>
          </div>
          <div className="toggle-btn">
            <span></span>
            <span></span>
            <span></span>
          </div>
          <div className="outer-side-nav">
            <div className="inner-side-nav">
              <ul className="main-nav">
                <li>
                  <button type="button" onClick={this.props.welcomeHandler}>
                    Welcome
                  </button>
                </li>
                <li>
                  <NavLink activeClassName="active" to="/agenda">
                    Agenda
                  </NavLink>
                </li>
                <li>
                  <NavLink activeClassName="active" to="/highlight">
                    Highlights
                  </NavLink>
                </li>
                <li>
                  {/* <a
                    href={`https://events.aka1908.net/21991000/${this.state.userTokenMain}?redirect=recordings`}
                    target="_blank"
                  >
                    Convention Center
                  </a> */}
                  <button type="button" onClick={this.props.lobbyHallOpen}>
                    Convention Center
                  </button>
                </li>
                {/* <li>
                  <a
                    href="https://events.aka1908.net/bouleleadership2021/vendors"
                    target="_blank">
                    Lobby & Exhibit Hall
                  </a>
                  <button type="button" onClick={this.props.lobbyHallOpen}>
                    Lobby & Exhibit Hall
                  </button>
                </li> */}
                <li>
                  <NavLink activeClassName="active" to="/faq">
                    FAQs
                  </NavLink>
                </li>
                <li>
                  <button type="button" onClick={this.props.helpdeskHandler}>
                    Help Desk
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    className="sponsors-btn"
                    onClick={this.props.sponserHandler}
                  >
                    Sponsors
                  </button>
                </li>
              </ul>
              <div className="signout-box">
                {this.state.fullName ? (
                  <span>Soror {this.state.fullName}
                    <span className="subText"></span>
                  </span>
                ) : null}
                <button
                  type="button"
                  className="btn"
                  onClick={this.props.openConfirm}
                >
                  SIGN OUT
                </button>
              </div>
              <ul className="social-nav">
                <li>
                  <a
                    href="https://www.facebook.com/akasorority1908/"
                    target="_blank"
                  >
                    <img src={FBIcon} />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/akasorority1908/"
                    target="_blank"
                  >
                    <img src={InstaIcon} />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.youtube.com/channel/UCIhtDU96BX6dqJd-3I14-pQ/featured"
                    target="_blank"
                  >
                    <img src={YTIcon} />
                  </a>
                </li>
              </ul>
              <p className="copyright-text">Copyright 2022</p>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Sidebar;
