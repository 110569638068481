import React from "react";
import BtnClose from "../../assets/images/btn-close.svg";
import "./auditoriumPopup.scss";

export const AuditoriumPopup = (props) => {
  // const [userDropdown, setuserDropdown] = useState(false);
  let takeButton = () => {
    window.open(props.hopinUrl, "_blank");
    props.auditoriumClose();
  };
  return (
    <div
      className={
        props.auditoriumToggle
          ? "aka-popup popup-mini auditorium-popup active"
          : "aka-popup popup-mini auditorium-popup"
      }
    >
      <div className="popup-inner">
        <button className="close" type="button" onClick={props.auditoriumClose}>
          <img src={BtnClose} alt="" />
        </button>
        <div className="custom-txt-box">
          <h2>Let's visit the Convention Center</h2>
          <p>
            By proceeding we will open a new tab with
            <br /> the access to the Convention Center.
            <br /> See you there!
          </p>

          <p className="count">{props.timer}s</p>

          <div className="btn-wrapper">
            {/* <a
              href={props.hopinUrl}
              target="_blank"
              className="btn btn-green fill"
            >
              Take me there
						</a> */}
            <button
              type="button"
              onClick={takeButton}
              className="btn btn-green fill"
            >
              Take me there
            </button>
            <button onClick={props.auditoriumClose} className="stay-here">
              I'd rather stay here
            </button>
            {/* <a className="stay-here" href="#">
              I'd rather stay here
            </a> */}
          </div>
        </div>
      </div>
    </div>
  );
};
