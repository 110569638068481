import React from "react";
import BtnClose from "../../assets/images/btn-close.svg";
import "./highlightPopup.scss";

export const HighlightPopup = (props) => {
  return (
    <div
      className={
        props.popupToggle
          ? "aka-popup highlight-popup active"
          : "aka-popup highlight-popup"
      }
    >
      <div className="popup-inner highlight-colm-box">
        <div className="inner-colm">
          <figure className="custom-img-box">
            <img src={props.selectedHighlight.image} alt="" />
          </figure>
          <div className="custom-txt-box">
            <button
              type="button"
              className="close main-close"
              onClick={props.popupHandler}
            >
              <img src={BtnClose} alt="" />
            </button>

            <h2 className="title">{props.selectedHighlight.title}</h2>
            <p className="subtitle">{props.selectedHighlight.subtitle}</p>
            <p>{props.selectedHighlight.description}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
