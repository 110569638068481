import React, { Component } from "react";
import { Holder } from "../holder";
import Sidebar from "../../components/sidebar/sidebar";
import { SponserPopup } from "../../components/sponserPopup/sponserPopup";
import { HelpdeskPopup } from "../../components/helpdeskPopup/helpdeskPopup";
import { AuditoriumPopup } from "../../components/auditoriumPopup/auditoriumPopup";
import { LobbyHallPopup } from "../../components/lobbyHallPopup/lobbyHallPopup";
import { ConfirmPopup } from "../../components/confirmPopup/confirmPopup";
import { WelcomePopup } from "../../components/welcomePopup/welcomePopup";
import $ from "jquery";

import axios from "axios";

const hasWindow = typeof window !== "undefined" ? true : false;
if (hasWindow) {
  window.loader = true;
}

class layout extends Component {
  state = {
    sponserToggle: false,
    helpdeskToggle: false,
    auditoriumToggle: false,
    lobbyHallToggle: false,
    timer: 5,
    openconfirm: false,
    hopinUrl: "",
    openStatus: false,
    welcomeToggle: false,
    email_address: "",
    vfairUrl: "",
  };

  componentDidMount() {
    // console.log("route change");
    let getHopinUrl = localStorage.getItem("hopinUrl");
    let email_address = localStorage.getItem("email_address");
    let openWelcomePopup = localStorage.getItem("open_welcome_popup");
    // console.log(openWelcomePopup);
    this.setState({
      hopinUrl: getHopinUrl,
      email_address: email_address,
      welcomeToggle: openWelcomePopup,
    });

    if (email_address) {
      axios
        .get(
          // `https://api.vfairs.com/v12/users?access_key=4hyr4hFaaFOGDgN9wyaKF22FKRsKzku6fkaN9&username=${email_address}`
          `https://api.vfairs.com/v12/users?access_key=wC9I1SqMbfSVJJOwLlm0MkkUI0dU4InQFOzVx`
        )
        .then((res) => {
          console.log(res.data[0].autologin_url);
          if (res.data) {
            this.setState({ vfairUrl: res.data[0].autologin_url });
          }
        });
    }
    if (hasWindow) {
      var interval = setInterval(() => {
        if (document.readyState === "complete") {
          clearInterval(interval);
          setTimeout(() => {
            // localStorage.removeItem("open_welcome_popup");
            this.setState({
              welcomeToggle: openWelcomePopup,
            });
          }, 4000);
        }
      }, 100);
    }
  }
  sponserHandler = () => {
    this.setState((prevState) => ({
      sponserToggle: !prevState.sponserToggle,
    }));
  };

  helpdeskHandler = () => {
    this.setState((prevState) => ({
      helpdeskToggle: !prevState.helpdeskToggle,
    }));
  };

  popupOpen = (popupName) => {
    var statuss = true;
    this.setState({
      [popupName]: true,
      openStatus: true,
    });
    // setTimeout(() => {
    //   this.setState({
    //     [popupName]: false,
    //   });
    // }, 5000);
    this.countdownSetus(popupName);
  };
  popupClose = (popupName) => {
    this.setState({
      openStatus: false,
      [popupName]: false,
      timer: 5,
      // timer: 0,
    });
    // this.countdownSetus(popupName, true);
    clearInterval(this.countdown);
    // setTimeout(() => {
    //   this.setState({
    //     timer: 5,
    //   });
    // }, 500);
  };
  countdown = null;
  countdownSetus = (popupName, stop = null) => {
    console.log(popupName);
    this.countdown = setInterval(() => {
      let timess = this.state.timer - 1;
      this.setState({
        timer: timess,
      });

      if (timess < 0) {
        if (popupName == "auditoriumToggle") {
          window.open(this.state.hopinUrl, "_blank");
        }
        if (popupName == "lobbyHallToggle") {
          this.takeHandler();
        }

        clearInterval(this.countdown);

        this.setState({
          timer: 5,
          [popupName]: false,
        });
      }
    }, 1000);
  };

  takeHandler = () => {
    window.open(this.state.vfairUrl, "_blank");
  };

  confirmHandler = () => {
    this.setState((prevState) => ({ openconfirm: !prevState.openconfirm }));
  };
  confirmOk = () => {
    this.setState({ openconfirm: false });
    localStorage.removeItem("userToken");
    localStorage.removeItem("fullName");
    localStorage.removeItem("email_address");
    localStorage.removeItem("hopinUrl");
    localStorage.removeItem("open_welcome_popup");
    window.location.href = "/login";
  };

  welcomePopupOpen = () => {
    this.setState({
      welcomeToggle: true,
    });
    // setTimeout(() => {
    //   this.setState({
    //     welcomeToggle: false,
    //   });
    // }, 3000);
  };

  welcomePopupClose = () => {
    this.setState({
      welcomeToggle: false,
    });
  };
  checkHandler = (e) => {
    // console.log(e.target.checked);
    if (e.target.checked) {
      localStorage.removeItem("open_welcome_popup");
    } else {
      localStorage.setItem("open_welcome_popup", true);
    }
  };

  render() {
    return (
      <div
        className={
          this.props.locatKey == "vfairsredirect"
            ? "main-section inner-page login-page"
            : "main-section" && this.props.locatKey == "hopinredirect"
              ? "main-section inner-page login-page"
              : "main-section" && this.props.locatKey == "login"
                ? "main-section inner-page login-page"
                : "main-section" && this.props.locatKey == ""
                  ? "main-section"
                  : "main-section inner-page"
        }
      >
        <Sidebar
          welcomeHandler={this.welcomePopupOpen}
          sponserHandler={this.sponserHandler}
          helpdeskHandler={this.helpdeskHandler}
          auditoriumOpen={() => this.popupOpen("auditoriumToggle")}
          lobbyHallOpen={() => this.popupOpen("lobbyHallToggle")}
          openConfirm={() => this.setState({ openconfirm: true })}
        />
        {this.props.children}
        <ConfirmPopup
          openconfirm={this.state.openconfirm}
          closeConfirm={() => this.setState({ openconfirm: false })}
          okHandler={this.confirmOk}
        />
        <AuditoriumPopup
          hopinUrl={this.state.hopinUrl}
          vfairUrl={this.state.vfairUrl}
          timer={this.state.timer}
          auditoriumToggle={this.state.auditoriumToggle}
          auditoriumClose={() => this.popupClose("auditoriumToggle")}
        />
        <LobbyHallPopup
          hopinUrl={this.state.hopinUrl}
          vfairUrl={this.state.vfairUrl}
          timer={this.state.timer}
          lobbyHallToggle={this.state.lobbyHallToggle}
          lobbyHallClose={() => this.popupClose("lobbyHallToggle")}
        />

        <SponserPopup
          sponserHandler={this.sponserHandler}
          sponserToggle={this.state.sponserToggle}
        />
        <HelpdeskPopup
          vfairUrl={this.state.vfairUrl}
          helpdeskHandler={this.helpdeskHandler}
          helpdeskToggle={this.state.helpdeskToggle}
        />
        {this.state.welcomeToggle ? (
          <WelcomePopup
            checkHandler={this.checkHandler}
            welcomeHandler={this.welcomePopupClose}
            welcomeToggle={this.state.welcomeToggle}
          />
        ) : null}
      </div>
    );
  }
}
export default layout;
