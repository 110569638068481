import React from "react";
import BtnClose from "../../assets/images/btn-close.svg";
import "./helpdeskPopup.scss";

export const HelpdeskPopup = (props) => {
  return (
    <div
      class={props.helpdeskToggle ? "helpdesk-popup active" : "helpdesk-popup"}
    >
      <div class="helpdesk-sec">
        <button type="button" className="close" onClick={props.helpdeskHandler}>
          <img src={BtnClose} alt="" />
        </button>

        <h2>Help Desk / Soror Services</h2>
        <div class="row flex">
          <div class="colm">
            <div class="inner">
              <p>
                Need assistance accessing the
                <b> Virtual Convention Center? </b>Contact Virtual
                <br /> Soror Services. Help is available
                <br /> 8 AM - 10 PM EDT.
              </p>
              <a class="btn btn-green" href="https://www.formpl.us/form/4982636154060800">
                Contact Us
              </a>
            </div>
          </div>

          <div class="colm">
            <div class="inner">
              <p>
                For assistance with issues within the
                <b> Virtual Conference Center</b>, visit the Info Desk
                <br />in the lobby of the virtual platform.
              </p>
              <a class="btn btn-green" href="/vfairsredirect">
                Info Desk
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
